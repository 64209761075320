<template>
	<v-checkbox v-if="type === attributeType.boolean" v-model="localValue" v-bind="bindProps"/>
	<v-text-field v-else-if="type === attributeType.number" v-model.number="localValue" v-bind="bindProps"/>
	<v-text-field v-else-if="type === attributeType.string" v-model="localValue" v-bind="bindProps"/>
	<div
		v-else-if="[
			attributeType.multiChoice,
			attributeType.multiOptions,
			attributeType.yesNoMultiChoice,
		].includes(type)"
	>
		<v-text-field
			v-for="(item, i) in inputArray"
			:key="i"
			v-model="inputArray[i]"
			v-bind="{
				...bindProps,
				...(
					typeof multipleInputsBind === 'function'
					? multipleInputsBind(item, i)
					: multipleInputsBind
				),
			}"
			@input="val => {
				if (val && i === inputArray.length -1) inputArray.push(null)
				else if (!val && i > 0 && i === inputArray.length -1) inputArray.splice(i, 1);
				$emit('input', inputArray.filter(Boolean))
			}"
		/>
	</div>
	<v-menu
		v-else-if="type === attributeType.color"
		ref="menu"
		offset-y
		:close-on-content-click="false"
		max-width="300"
	>
		<template v-slot:activator="{ on, attr }">
			<v-text-field
				v-on="on"
				readonly
				v-bind="{ ...bindProps, ...attr }"
				:value="localValue"
			>
				<template v-slot:prepend-inner>
					<v-icon :color="localValue">mdi-circle</v-icon>
				</template>
			</v-text-field>
		</template>
		<v-card class="pa-1">
			<v-color-picker
				v-model="localValue"
				mode="hexa"
				v-bind="bindProps"
			/>
			<v-card-actions class="pt-0 justify-end">
				<v-btn
					small
					color="primary"
					class="rounded-lg"
					@click="$refs.menu.save(localValue)"
				>
					{{$t('close')}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>
import { attributeType } from '@/helpers/enums'
export default {
	name: 'BeeAttributeInput',
	props: {
		type: { type: Number, default: attributeType.string },
		label: { type: String },
		dense: { type: Boolean },
		readonly: { type: Boolean },
		outlined: { type: Boolean },
		'multiple-inputs-bind': { type: [Object, Function] },
		'hide-details': { type: [Boolean, String] },
		rules: { type: Array },
		inputProps: { type: Object, default: () => ({}) },
		value: { type: [String, Number, Boolean, Array] }
	},

	data: () => ({
		attributeType,

		inputArray: [
			null
		],
	}),

	watch: {
		value: {
			handler(val) {
				if (
					[
						attributeType.multiChoice,
						attributeType.multiOptions,
						attributeType.yesNoMultiChoice
					].includes(this.type) &&
					Array.isArray(val)
				) {
					this.inputArray = [...val, null];
				}
			},
			immediate: true,
		}
	},

	computed: {
		bindProps() {
			return {
				label: this.label,
				dense: this.dense,
				readonly: this.readonly,
				outlined: this.outlined,
				'hide-details': this.hideDetails,
				rules: this.rules,
				inputProps: this.inputProps,
				class: 'rounded-medium'
			}
		},

		localValue: {
			get() {
				return this.value;
			},
			set(val) {
				if (
					[
						attributeType.multiChoice,
						attributeType.multiOptions,
						attributeType.yesNoMultiChoice
					].includes(this.type)
				) {
					this.$emit('input', this.inputArray);
				} else {
					this.$emit('input', val);
				}
			}
		}
	}
}
</script>

<style>

</style>