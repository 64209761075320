<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="ratings"
			:ids="deleteItemIds"
			@on-delete="fetchRatings"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Ratings.the-rating')}}
		</bee-delete-dialog>

		<!-- add/edit dialog -->
		<v-dialog v-model="dialog" width="600" persistent>
			<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Ratings.rating')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:checked-langs="acceptedToSaveLangs"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row dense align="center" class="mt-0">
							<!-- product -->
							<v-col sm="6" cols="12">
								<v-autocomplete
									v-model="productId"
									:label="$t('inputs.product-name')"
									:items="products"
									item-text="name"
									item-value="id"
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.requiredAsNumber"
								></v-autocomplete>
							</v-col>
							
							<!-- user -->
							<v-col sm="6" cols="12">
								<v-autocomplete
									v-model="userId"
									:label="$t('inputs.user')"
									:items="users"
									item-text="fullName"
									item-value="id"
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.requiredAsNumber"
								></v-autocomplete>
							</v-col>

							<!-- date -->
							<v-col sm="6" cols="12">
								<v-menu
									v-model="dateMenu"
									transition="scale-transition"
									offset-y
									:close-on-content-click="false"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="date"
											:label="$t('inputs.from-date')"
											prepend-inner-icon="mdi-calendar"
											hide-details
											outlined
											dense
											class="rounded-medium"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="date"
										:max="new Date().toISOString().slice(0, 10)"
										@input="dateMenu = false"
									></v-date-picker>
								</v-menu>
							</v-col>
							
							<!-- rating -->
							<v-col sm="6" cols="12" class="d-flex align-center">
								<span class="text-body-1 me-1">{{$t('inputs.rating')}}:</span>
								<v-rating
									v-model="stars"
									outlined
									dense
									hide-details
									hover
									:readonly="dialogLoading"
									:background-color="isSubmitted && !stars ? 'error' : undefined"
									:size="26"
								></v-rating>
							</v-col>
							
							<!-- comment -->
							<v-col cols="12">
								<v-textarea
									v-model="comment"
									:label="$t('inputs.comment')"
									outlined
									dense
									class="rounded-small"
									hide-details
									rows="3"
									auto-grow
									:rules="rules.required"
								></v-textarea>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							:loading="dialogLoading"
							:disabled="dialogLoading"
							class="rounded-small"
						>{{$t('save')}}</v-btn>
						<v-btn
							:disabled="dialogLoading"
							class="rounded-small"
							@click="dialog = false"
						>{{$t('cancel')}}</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filters -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Ratings.add-new-rating')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredRatings"
			:loading="loading"
			dense
			:items-per-page="15"
			disable-sort
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.ratingHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- rating -->
			<template v-slot:item.rating="{ item }">
				<v-rating :value="+item.stars" dense readonly :size="20"/>
			</template>
			
			<!-- comment -->
			<template v-slot:item.comment="{ item }">
				{{item.commentData?.comment??'-'}}
			</template>

			<!-- date -->
			<template v-slot:item.date="{ item }">
				{{moment(item.date).format('YYYY-MM-DD')}}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex justify-center align-center">
					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="ms-1 px-1"
							min-width="20px"
							@click="
								deleteDialog = true;
								deleteItemIds = { ratingId: item.id, commentId: item.commentData?.id };
							"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import rules from '@/helpers/validation rules'
import langsDraft from '@/mixins/langs-draft';
import moment from 'moment';

export default {
	name: 'Ratings',
	mixins: [langsDraft],

	data: () => ({
		dialog: false,
		editItemId: null,
		
		deleteDialog: false,
		deleteItemIds: null,

		isSubmitted: false,

		dateMenu: false,
		moment,

		// filter
		search: null,
		isFiltered: false,

		// configs
		multiLangInputs,

		// helpers
		loading: null,
		dialogLoading: null,
		lang: defaultLang,
		rules,

		// draft for languages 
		draft: {},
		draftNames: ['comment'],

		// data
		userId: null,
		productId: null,
		date: new Date().toISOString().slice(0, 10),
		stars: 0,
		comment: null,
	}),

	watch: {
		dialog(val) {
			if (!val) {
				this.$refs.form.reset();
				this.date = new Date().toISOString().slice(0, 10);
				this.stars = 0;
				this.resetDraft();
				this.editItemId = null;
				this.lang = defaultLang;
			}
		}
	},

	computed: {
		...mapState({
			users: state => state.users.users.filter(c => !c.isBanned),
			products: state => state.products.products,
			ratings: state => state.ratings.ratings,
		}),

		...mapGetters({
			getUserById: 'users/getUserById',
		}),

		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.product'),
					value: 'product.name',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true,
				},
				{
					text: this.$t('headers.user'),
					value: 'user.fullName',
					align: 'center',	
					noRemove: true,
				},
				{
					text: this.$t('headers.rating'),
					value: 'rating',
					align: 'center',
				},
				{
					text: this.$t('headers.comment'),
					value: 'comment',
					align: 'center',
				},
				{
					text: this.$t('headers.date'),
					value: 'date',
					align: 'center',
					width: 120
				},
				// notInDefaultView
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					noRemove: true,
					fixed: true,
					width: 140
				},
			]

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.ratingHeaders.length) {
				headers = this.$eStorage.l.ratingHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		filteredRatings() {
			return this.ratings.filter(c =>
				this.isFiltered ? c.product.name.toLowerCase().includes(this.search || '') : true
			);
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.comment[lang.key]) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		async submit() {
			this.isSubmitted = true;
			if (this.$refs.form.validate() && this.stars) {
				this.isSubmitted = false;
				this.dialogLoading = true;
				
				const promises = [];
				let itemData = null;
				
				try {
					await this.$store.dispatch('ratings/createRating', {
						userId: this.userId,
						productId: this.productId,
						stars: this.stars,
					});
				} catch (error) {
					this.dialogLoading = false;
					throw error;
				}

				const submitRequest = (
					isDefaultOne,
					{ comment, lang },
					id = this.editItemId
				) => {
					const user = this.getUserById(this.userId);
					/* const actionName = id ? 'updateComment' : 'createComment';
					return this.$store.dispatch(`ratings/${actionName}`, { */
					return this.$store.dispatch('ratings/createComment', {
						id: id,
						userId: this.userId,
						productId: this.productId,
						comment: comment,
						date: this.date,
						userName: user.userName,
						email: user.email,
						acceptLanguage: lang
					}).then((data) => data)
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const comment = this.draft.comment[lang];

					const dataDueLang = { comment, lang };

					if (comment) {
						if (!itemData) {
							await submitRequest(!itemData, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				const successHandler = () => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Ratings.rating') })
							: this.$t('$-is-created-successfully', { name: this.$t('Ratings.rating') });
					this.$eventBus.$emit('show-snackbar', false, message);
					
					this.fetchRatings();
					this.dialog = false;
				};
				const errorHandler = () => {
					if (itemData) {
						this.editItemId = itemData.id;
					}
				}

				Promise.all(promises).then(successHandler).catch(errorHandler).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		fetchRatings(isFirstLoad) {
			this.loading = true;
			return this.$store.dispatch('ratings/fetchAll').finally(() => {
				if (!isFirstLoad) this.loading = false;
			});
		},

		fetchRating() {
			/* this.dialogLoading = true;
			const slug = this.getCouponById(this.editItemId).slug;

			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('coupons/fetchBySlug', { slug, acceptLanguage: c.key })
						.then((data) => {
							if (index === 0) {
								this.maxUses = data.maxUsers;
								this.couponCode = data.code;
								this.beneficiariesIds = data.users;
								// this.couponProducts = data.couponProducts;
								this.fromDate = data.fromDate ? moment(data.fromDate).format('YYYY-MM-DD') : null;
								this.toDate = data.toDate ? moment(data.toDate).format('YYYY-MM-DD') : null;
								this.discountType = data.fixedValue ? 1 : 0;
								this.discount = data.percentValue || data.fixedValue;
								this.isActive = data.isActive;
							}
							this.draft.name[c.key] = data.name;
						})
				);
			});

			Promise.all(promises).then(() => {
				this.name = this.draft.name[this.lang];
			}).finally(() => {
				this.dialogLoading = false;
			}); */
		},
	},

	created() {
		this.loading = true;
		Promise.all([
			this.fetchRatings(),
			!this.products.length ? this.$store.dispatch('products/fetchAll', {}) : null,
			!this.users.length ? this.$store.dispatch('users/fetchAll', {}) : null,
		]).finally(() => {
			this.loading = false;
		})
	}
}
</script>

<style>

</style>