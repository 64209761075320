var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type === _vm.attributeType.boolean)?_c('v-checkbox',_vm._b({model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'v-checkbox',_vm.bindProps,false)):(_vm.type === _vm.attributeType.number)?_c('v-text-field',_vm._b({model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=_vm._n($$v)},expression:"localValue"}},'v-text-field',_vm.bindProps,false)):(_vm.type === _vm.attributeType.string)?_c('v-text-field',_vm._b({model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'v-text-field',_vm.bindProps,false)):([
		_vm.attributeType.multiChoice,
		_vm.attributeType.multiOptions,
		_vm.attributeType.yesNoMultiChoice,
	].includes(_vm.type))?_c('div',_vm._l((_vm.inputArray),function(item,i){return _c('v-text-field',_vm._b({key:i,on:{"input":val => {
			if (val && i === _vm.inputArray.length -1) _vm.inputArray.push(null)
			else if (!val && i > 0 && i === _vm.inputArray.length -1) _vm.inputArray.splice(i, 1);
			_vm.$emit('input', _vm.inputArray.filter(Boolean))
		}},model:{value:(_vm.inputArray[i]),callback:function ($$v) {_vm.$set(_vm.inputArray, i, $$v)},expression:"inputArray[i]"}},'v-text-field',{
			..._vm.bindProps,
			...(
				typeof _vm.multipleInputsBind === 'function'
				? _vm.multipleInputsBind(item, i)
				: _vm.multipleInputsBind
			),
		},false))}),1):(_vm.type === _vm.attributeType.color)?_c('v-menu',{ref:"menu",attrs:{"offset-y":"","close-on-content-click":false,"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attr }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","value":_vm.localValue},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.localValue}},[_vm._v("mdi-circle")])]},proxy:true}],null,true)},'v-text-field',{ ..._vm.bindProps, ...attr },false),on))]}}])},[_c('v-card',{staticClass:"pa-1"},[_c('v-color-picker',_vm._b({attrs:{"mode":"hexa"},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'v-color-picker',_vm.bindProps,false)),_c('v-card-actions',{staticClass:"pt-0 justify-end"},[_c('v-btn',{staticClass:"rounded-lg",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.localValue)}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }