<template>
	<v-form ref="form" @submit.prevent="submit" class="px-2">
		<!-- lang tabs -->
		<v-lang-tabs
			v-if="multiLangInputs"
			v-model="lang"
			:checked-langs="acceptedToSaveLangs"
			:disable-non-selected="loading || submitLoading"
			class="mb-2"
			@change="updateFieldsFromDraft"
		/>

		<!-- inputs -->
		<v-row class="mt-0 mb-5">
			<!-- input data -->
			<v-col lg="9" md="8" cols="12" order-md="0" order="1" class="pb-md-0">
				<v-row dense class="align-center mt-0 mb-2">
					<!-- title -->
					<v-col :sm="useInHomePage ? 4 : 6" cols="12">
						<v-text-field
							v-model="title"
							:label="$t('inputs.title')"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
							:rules="rules.required"
						></v-text-field>
					</v-col>

					<!-- date-of-publication & is-featured -->
					<v-col v-if="0" lg="3" sm="6" cols="12">
						<!-- date -->
						<v-menu
							ref="menu"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
							min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="date"
									:label="$t('inputs.date')"
									hide-details
									outlined
									dense
									class="rounded-medium"
									prepend-inner-icon="mdi-calendar"
									clearable
									:loading="loading"
									:disabled="loading || submitLoading"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date"
								:max="(new Date().getFullYear() + 1) + new Date().toISOString().slice(4, 10)"
								min="1950-01-01"
								@change="$refs.menu.save(date)"
							></v-date-picker>
						</v-menu>
					</v-col>

					<!-- is-featured -->
					<v-col v-if="0" md="auto" sm="3" cols="auto">
						<v-switch
							v-model="isFeatured"
							:label="$t('inputs.is-featured')"
							hide-details
							:disabled="loading || submitLoading"
							class="mt-sm-0 mt-0 pt-0"
						></v-switch>
					</v-col>

					<!-- in home -->
					<v-col v-if="false && useInHomePage" md="auto" sm="3" cols="auto">
						<v-switch
							v-model="inHomePage"
							:label="$t('inputs.is-in-home-page')"
							hide-details
							:disabled="loading || submitLoading"
							class="mt-sm-0 mt-0 pt-0"
						></v-switch>
					</v-col>

					<!-- summary -->
					<v-col v-if="0" cols="12">
						<v-textarea
							v-model="summary"
							:label="$t('inputs.summary')"
							auto-grow
							no-resize
							rows="1"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading"
							:disabled="loading || submitLoading"
						></v-textarea>
					</v-col>

					<!-- filters & filters tree-->
					<template v-if="showFilters && useFiltersFor.customServices">
						<!-- filters -->
						<v-col v-if="!useFilterTree" lg="4" sm="6" cols="12">
							<v-autocomplete
								v-model="filterIds"
								:label="$t('inputs.filter')"
								:items="filters"
								item-text="name"
								item-value="id"
								multiple
								hide-details
								outlined
								dense
								chips
								small-chips
								deletable-chips
								class="rounded-medium"
								:loading="loading || assetsLoading"
								:disabled="loading || submitLoading"
							></v-autocomplete>
						</v-col>
						
						<!-- filters tree -->
						<v-col v-else lg="4" sm="6" cols="12">
							<v-tree-autocomplete
								v-model="filterIds"
								:label="$t('inputs.filter')"
								:items="filters"
								:treeItems="hierarchyFilters"
								chips
								small-chips
								deletable-chips
								dense
								hide-details
								outlined
								:loading="loading || assetsLoading"
								:disabled="loading || submitLoading"
								input-class="rounded-medium"
							></v-tree-autocomplete>
						</v-col>
					</template>

					<!-- code -->
					<v-col lg="4" sm="6" cols="12">
						<v-autocomplete
							v-model="code"
							label="service name"
							:items="products"
							item-text="name"
							item-value="slug"
							hide-details
							outlined
							dense
							class="rounded-medium"
							:loading="loading || assetsLoading"
							:disabled="loading || submitLoading"
						></v-autocomplete>
					</v-col>
				</v-row>
				
				<!-- SEO fields -->
				<template v-if="showSEO">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('SEO-fields')}}
					</div>
					<v-row dense>
						<!-- SEO keywords -->
						<v-col cols="12">
							<v-chips-input
								v-model="SEOkeywords"
								:label="$t('inputs.seo-keywords')"
								hide-details
								outlined
								dense
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
							></v-chips-input>
						</v-col>

						<!-- SEO description -->
						<v-col cols="12">
							<v-textarea
								v-model="SEOdescription"
								:label="$t('inputs.seo-description')"
								outlined
								dense
								auto-grow
								rows="1"
								no-resize
								counter
								:hint="SEOdescription && SEOdescription.length > 155 ? 'It\'s best to keep descriptions between 155-160 characters' : null"
								persistent-hint
								:color="SEOdescription && SEOdescription.length > 155 ? 'warning' : null"
								class="rounded-medium"
								:loading="loading"
								:disabled="loading || submitLoading"
								@keypress.enter.prevent
								@change="SEOdescription = SEOdescription.replace(/\n/g, ' ')"
							></v-textarea>
						</v-col>
					</v-row>
				</template>

				<!-- description -->
				<div class="text-body-1 font-weight-bold text-capitalize mb-3">{{$t('inputs.description')}}</div>
				<text-editor
					v-model="description"
					:error="!Boolean(description) && isSubmitted"
					:loading="loading"
					:use-template-builder="allowTemplateBuilder"
				/>
			</v-col>

			<v-col cols="auto" class="d-md-block d-none px-1">
				<v-divider vertical/>
			</v-col>

			<!-- drop image & videos & audio -->
			<v-col md cols="12" class="pb-md-0">
				<div class="sticky">
					<div class="text-body-1 font-weight-bold mb-3">
						{{$t('upload-images')}}
					</div>
					<bee-drop-img
						v-model="mainImage"
						dashed
						border-width="2"
						:border-color="isSubmitted && !mainImage ? '#f00' : '#ccc'"
						radius="11"
						height="250px"
						blurable
						clearable
						:loading="loading"
						:disabled="loading || submitLoading"
						:hide-image-name="false"
						disable-file-picker
						@remove-image="fileManagerFiles.mainImage = {}"
						@click="$eventBus.$emit('file-manager', { images: true }, (url, file) => {
							mainImage = url;
							fileManagerFiles.mainImage = file;
						})"
					>
						<template v-slot:drag-text>
							<span class="text-body-1 font-weight-medium">
								{{$t('drag-text')}}
							</span>
							<span class="text-caption mb-0">
								{{$t('supported-text')}}
							</span>
						</template>
					</bee-drop-img>
					
					<bee-drop-zone
						v-if="uploadMoreImages"
						v-model="images"
						dashed
						drop-zone-classes="pa-3"
						radius="11"
						border-width="2"
						max-height="250"
						:drop-image-props="{
							width: 70,
							height: 70
						}"
						:clear-icon="$route.params.id ? 'mdi-delete' : 'mdi-close'"
						:clear-color="$route.params.id ? 'red' : null"
						:gap-size="4"
						:drop-menu-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-max-width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
						:drop-menu-left="null"
						show-add-btn
						show-drop-menu
						no-drop-replace
						class="mt-3"
						:loading="loading"
						:disabled="loading || submitLoading"
						disable-file-picker
						@remove-image="removeAttachment($event, 'image')"
						@click="$eventBus.$emit('file-manager', { images: true, multiple: true }, (urls, files) => {
							images.push(...urls);
							fileManagerFiles.moreImages.push(...files);
						})"
					>
						<template v-slot:drag-text>
							<p class="mb-0">
								<span class="d-block text-body-1 font-weight-bold">
									{{$t('upload-more-image')}}
								</span>
								<span class="d-block text-caption mb-0">{{$t('drag-text')}}</span>
							</p>
						</template>
					</bee-drop-zone>

					<!-- adding videos -->
					<bee-video-zone
						v-if="showVideos"
						v-model="videos"
						no-drop-replace
						:loading="loading"
						:disabled="loading || submitLoading"
						class="mt-3"
						@click="$eventBus.$emit('file-manager', { videos: true, multiple: true }, (urls, files) => {
							videos.push(...files.map(c => ({ id: c.id, name: c.name, url: c.url })));
							fileManagerFiles.videos.push(...files);
						})"
						@remove-video="removeAttachment($event, 'video')"
					/>

					<!-- adding audio -->
					<bee-audio-zone
						v-if="showAudios"
						v-model="audios"
						no-drop-replace
						:loading="loading"
						:disabled="loading || submitLoading"
						class="mt-3"
						@click="$eventBus.$emit('file-manager', { audios: true, multiple: true }, (urls, files) => {
							audios.push(...files.map(c => ({ id: c.id, name: c.name, url: c.url })));
							fileManagerFiles.audios.push(...files);
						})"
						@remove-audio="removeAttachment($event, 'audio')"
					/>
				</div>
			</v-col>
		</v-row>
		<v-btn
			type="submit"
			color="primary"
			class="rounded-medium me-2"
			:loading="submitLoading"
			:disabled="loading || submitLoading"
		>
			{{$t('save')}}
		</v-btn>
		<v-btn
			color="white"
			class="rounded-medium"
			:to="{ name: backRouteName }"
			:disabled="loading || submitLoading"
		>
			{{$t('back')}}
		</v-btn>
	</v-form>
</template>

<script>
import moment from 'moment';
import rules from '@/helpers/validation rules'
import { filters, SEO } from '@/configs/routes.config';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import { allowTemplateBuilder, useFiltersFor, useFilterTree, useInHomePage, useUploadMoreImages, useVideos, useMoreVideos, useAudios, useMoreAudios } from '@/configs/global.config';
import { customServices } from '@/configs/custom-pages.config';
import langsDraft from '@/mixins/langs-draft';
import { mapState } from 'vuex';

export default {
	name: 'CustomService',
	mixins: [langsDraft],

	data: () => ({
		loading: false,
		assetsLoading: false,
		submitLoading: false,
		isSubmitted: false,
		productAttachments: [],
		lang: defaultLang,
		rules,

		// configs
		showSEO: SEO.show,
		uploadMoreImages: useUploadMoreImages.customServices,
		useInHomePage,
		multiLangInputs,
		showFilters: filters.show,
		useFiltersFor,
		useFilterTree,
		showVideos: useVideos && useMoreVideos.customServices,
		showAudios: useAudios && useMoreAudios.customServices,
		allowTemplateBuilder,

		// file manager
		fileManagerFiles: {
			mainImage: {},
			moreImages: [],
			videos: [],
			audios: [],
		},

		// draft for languages 
		draft: {},
		draftNames: [
			'title',
			'summary',
			'SEOkeywords',
			'SEOdescription',
			{ name: 'description', value: '' }
		],
		
		// data
		title: null,
		summary: null,
		code: null,
		date: null,
		isFeatured: null,
		inHomePage: null,
		filterIds: [],
		description: '<p></p>',
		mainImage: null,
		images: [],
		videos: [],
		audios: [],
		SEOkeywords: null,
		SEOdescription: null,
	}),

	watch: {
		$route(newVal) {
			if (newVal.params.id) {
				this.loading = true;
				this.fetchCustomService().finally(() => {
					this.loading = false;
				});
			}
		}
	},

	computed: {
		...mapState({
			filters: state => state.filters.filters.filter(c => !c.deletedAt),
			hierarchyFilters: state => state.filters.hierarchyFilters,
			products: state => state.products.products.filter(c => !c.deletedAt),
		}),

		currentType() {
			return Number(this.$route.params.type);
		},
		currentTypeName() {
			return customServices.find(c => c.type === this.currentType).typeName;
		},
		currentTypeTranslate() {
			return customServices.find(c => c.type === this.currentType).translates[this.$i18n.locale].singular;
		},
		backRouteName() {
			return customServices.find(c => c.type === this.currentType).mainPageRoute.name;
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.title[lang.key] && this.draft.description[lang.key]) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		async submit() {
			this.isSubmitted = true;
			if (this.$refs.form.validate() && this.mainImage && this.description) {
				this.submitLoading = true;
				// more images
				const images = this.images.filter(c => typeof c !== 'string');

				// videos uploaded by drop
				const videos = this.videos.filter(c => c.size);
				// audios uploaded by drop
				const audios = this.audios.filter(c => c.size);

				const promises = [];
				let imagesPromise = null;
				let videosPromise = null;
				let audiosPromise = null;
				let itemData = null;

				const submitRequest = (
					isDefaultOne,
					{ title, summary, description, SEOkeywords, SEOdescription, lang },
					id = this.$route.params.id
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`customServices/${actionName}`, {
						id: id,
						type: this.currentType,
						typeName: this.currentTypeName,
						title: title,
						summary: summary,
						filterIds: this.filterIds,
						code: this.code,
						date: this.date,
						isFeatured: this.isFeatured,
						inHomePage: this.inHomePage,
						content: description,
						image:
							isDefaultOne && typeof this.mainImage !== 'string'
								? this.mainImage
								: null,
						imagePath:
							isDefaultOne && typeof this.mainImage === 'string'
								? this.fileManagerFiles.mainImage.publicPath
								: null,
						imageName:
							isDefaultOne && typeof this.mainImage === 'string'
								? this.fileManagerFiles.mainImage.fileName
								: null,
						keywordsSeo: SEOkeywords,
						descriptionSeo: SEOdescription,
						acceptLanguage: lang
					}).then((data) => {
						if (isDefaultOne && (images.length || this.fileManagerFiles.moreImages.length)) {
							imagesPromise = this.$store.dispatch('customServices/uploadImages', {
								id: data.id,
								type: this.currentType,
								typeName: this.currentTypeName,
								images,
								imagesPaths: this.fileManagerFiles.moreImages.map(c => c.publicPath),
								imagesNames: this.fileManagerFiles.moreImages.map(c => c.fileName),
							})
						}
						if (isDefaultOne && (videos.length || this.fileManagerFiles.videos.length)) {
							videosPromise = this.$store.dispatch('customServices/uploadVideos', {
								id: data.id,
								type: this.currentType,
								typeName: this.currentTypeName,
								videos,
								videosPaths: this.fileManagerFiles.videos.map(c => c.publicPath),
								videosNames: this.fileManagerFiles.videos.map(c => c.fileName),
							})
						}
						if (isDefaultOne && (audios.length || this.fileManagerFiles.audios.length)) {
							audiosPromise = this.$store.dispatch('customServices/uploadAudios', {
								id: data.id,
								type: this.currentType,
								typeName: this.currentTypeName,
								audios,
								audiosPaths: this.fileManagerFiles.audios.map(c => c.publicPath),
								audiosNames: this.fileManagerFiles.audios.map(c => c.fileName),
							})
						}
						return data;
					})
				}

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const title = this.draft.title[lang];
					const summary = this.draft.summary[lang];
					const SEOkeywords = this.draft.SEOkeywords[lang];
					const SEOdescription = this.draft.SEOdescription[lang];
					const description = this.draft.description[lang];

					const dataDueLang = { title, summary, SEOkeywords, SEOdescription, description, lang };

					if (title && description) {
						if (!itemData) {
							await submitRequest(!itemData, dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(false, dataDueLang, itemData.id));
						}
					}
				}

				const successHandler = () => {
					const message =
							this.$route.params.id
								? this.$t('$-is-updated-successfully', { name: this.currentTypeTranslate })
								: this.$t('$-is-created-successfully', { name: this.currentTypeTranslate });
					this.$eventBus.$emit('show-snackbar', false, message);
					this.$router.push({ name: this.backRouteName });
				}
				const errorHandler = () => {
					if (itemData) {
						this.$router.replace({
							name: this.$route.name,
							params: { slug: itemData.slug, id: itemData.id }
						});
					}
				}

				const mediaPromise = Promise.all([imagesPromise, videosPromise]);

				Promise.all(promises).then(() => {
					if (!imagesPromise && !videosPromise && !audiosPromise) successHandler();
					else {
						this.$eventBus.$emit('show-snackbar', false, this.$t('please-wait-uploading-additional-data'));
						mediaPromise.catch(errorHandler).then(successHandler);
					}
				}).catch(
					errorHandler
				).finally(() => {
					if (!imagesPromise && !videosPromise && !audiosPromise) this.submitLoading = false;
					else mediaPromise.finally(() => { this.submitLoading = false });
				})
			}
		},

		// FIXME show dialog to confirm remove
		removeAttachment(fileUrl, mediaType = 'image') {
			let collectionType;
			switch (mediaType) {
			case 'image': collectionType = ''; break;
			case 'video': collectionType = ' - video'; break;
			case 'audio': collectionType = ' - audio'; break;
			}
			
			if (this.$route.params.id) {
				const attachmentId = this.productAttachments.find(c => c.url === fileUrl)?.id;

				if (attachmentId) {
					this.$store.dispatch('customServices/deleteImage', {
						id: this.$route.params.id,
						type: this.currentType,
						typeName: this.currentTypeName + collectionType,
						imageId: attachmentId
					})
				}
			}

			let fileManagerProp;
			switch (mediaType) {
			case 'image': fileManagerProp = 'moreImages'; break;
			case 'video': fileManagerProp = 'videos'; break;
			case 'audio': fileManagerProp = 'audio'; break;
			}

			const fileManagerImageId =
				this.fileManagerFiles[fileManagerProp].find(c => c.url === fileUrl)?.id;
			if (fileManagerImageId) {
				const imageIndex =
					this.fileManagerFiles[fileManagerProp].findIndex(c => c.id === fileManagerImageId);
				this.fileManagerFiles[fileManagerProp].splice(imageIndex, 1);
			}
		},

		fetchCustomService() {
			const promises = [];

			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('customServices/fetchBySlug', {
						slug: this.$route.params.slug,
						type: this.currentType,
						typeName: this.currentTypeName,
						acceptLanguage: c.key
					}).then((data) => {
						if (index === 0) {
							this.code = data.code;
							this.date = data.date ? moment(data.date).format('YYYY-MM-DD') : null;
							this.isFeatured = data.isFeatured;
							this.inHomePage = data.inHomePage;
							this.mainImage = data.primaryImage.url;
							this.images = data.images.map(c => c.url).filter(c => c !== data.primaryImage.url);

							if (this.showVideos) {
								this.videos = data.videos.map(c => ({ id: c.id, name: c.name, url: c.url }));
							}
							if (this.showAudios) {
								this.audios = data.audio.map(c => ({ id: c.id, name: c.name, url: c.url }));
							}
							if (this.showFilters && useFiltersFor.customServices) {
								this.filterIds = data.filterIds;
							}

							this.productAttachments = [
								...data.images.filter(c => c.url !== data.primaryImage.url),
								...this.videos,
								...this.audios,
							];
						}

						this.draft.title[c.key] = data.title;
						this.draft.summary[c.key] = data.summary;
						this.draft.description[c.key] = data.content;
						this.draft.SEOkeywords[c.key] = data.keywordsSeo ? data.keywordsSeo : '';
						this.draft.SEOdescription[c.key] = data.descriptionSeo ? data.descriptionSeo : '';
					})
				)
			});
			
			return Promise.all(promises).then(() => {
				this.title = this.draft.title[this.lang];
				this.summary = this.draft.summary[this.lang];
				this.description = this.draft.description[this.lang];
				this.SEOkeywords = this.draft.SEOkeywords[this.lang];
				this.SEOdescription = this.draft.SEOdescription[this.lang];
			})
		}
	},

	created() {
		if (this.$route.params.id) {
			this.loading = true;
			Promise.all([
				this.fetchCustomService(),
				!this.products.length ? this.$store.dispatch('products/fetchAll', {}) : null
			]).finally(() => {
				this.loading = false;
			})
		}
		if (this.showFilters && useFiltersFor.customServices && !this.filters.length) {
			this.assetsLoading = true;
			Promise.all([
				this.$store.dispatch('products/fetchAll', {}),
				this.$store.dispatch('filters/fetchAll'),
				useFilterTree ? this.$store.dispatch('filters/fetchAllAsHierarchy') : null,
			]).finally(() => {
				this.assetsLoading = false;
			}); 
		}
	}
}
</script>

<style>

</style>