import Vue from 'vue'
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import BeeRestoreDialog from '@/components/BeeRestoreDialog.vue'
import BeeDropImg from '@/components/BeeDropImg.vue'
import BeeDropZone from '@/components/BeeDropZone.vue'
import BeeVideoZone from '@/components/BeeVideoZone.vue'
import BeeAudioZone from '@/components/BeeAudioZone.vue'
import BeeAttributeInput from '@/components/BeeAttributeInput.vue'
// import TextEditor from '@/components/TextEditor.vue'
import TextEditor from '@/components/CKeditor.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import AudioPlayer from '@/components/AudioPlayer.vue'
import VLangTabs from '@/components/VLangTabs.vue'
import VFileManagerDialog from '@/components/file-manager/VFileManagerDialog.vue'
import VChipsInput from '@/components/VChipsInput.vue'
import VTreeAutocomplete from '@/components/VTreeAutocomplete.vue';
import VHeadersControlDialog from '@/components/VHeadersControlDialog.vue'
import Tooltip from '@/components/Tooltip.vue'
import VTemplateBuilder from '@/components/template-builder/VTemplateBuilder.vue'
import ApexChart from '@/components/ApexChart.vue'

export const $eventBus = new Vue();
const midad = {
	install: (Vue, options) => {
		Vue.prototype.$eventBus = $eventBus;

		Vue.component('BeeDeleteDialog', BeeDeleteDialog);
		Vue.component('BeeRestoreDialog', BeeRestoreDialog);
		Vue.component('BeeDropImg', BeeDropImg);
		Vue.component('BeeDropZone', BeeDropZone);
		Vue.component('BeeVideoZone', BeeVideoZone);
		Vue.component('BeeAudioZone', BeeAudioZone);
		Vue.component('BeeAttributeInput', BeeAttributeInput);
		Vue.component('TextEditor', TextEditor);
		Vue.component('VideoPlayer', VideoPlayer);
		Vue.component('AudioPlayer', AudioPlayer);
		Vue.component('VLangTabs', VLangTabs);
		Vue.component('VFileManagerDialog', VFileManagerDialog);
		Vue.component('VChipsInput', VChipsInput);
		Vue.component('VTreeAutocomplete', VTreeAutocomplete);
		Vue.component('VHeadersControlDialog', VHeadersControlDialog);
		Vue.component('Tooltip', Tooltip);

		Vue.component('VTemplateBuilder', VTemplateBuilder);
		Vue.component('ApexChart', ApexChart);

		Vue.filter('isAvailable', (val) => {
			return val || '-';
		})
		Vue.filter('ellipsis', (val, length = 50) => {
			return val.length < length ? val : val.slice(0, length) + '...';
		})
		
		Vue.mixin({
			methods: {
				filterByName(activeFilter, items, search, nameProp = 'name') {
					if (activeFilter) {
						if (!items.length) return []
						return items.filter(c => c[nameProp].toLowerCase().includes((search || '').toLowerCase()));
					} else {
						return items;
					}
				}
			}
		})
	}
}

Vue.use(midad, null);

export default { }